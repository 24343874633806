import RfqLegalCopy from '@components/RfqLegalCopy/RfqLegalCopy';
import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import useElementDimensionsObserver from '@hooks/useElementDimensionsObserver';
import { reportServerSideExperiment } from '@redux/experiments';
import { uiReadabilityEnhancementsAssignmentSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import { IconButton } from '@xo-union/tk-component-icons';
import { Caption, Subhead } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import { VendorInfo } from '../bio/components/TeamInfo';
import Styles from './InlineRfq.scss';
import FormBody from './components/FormBody/FormBody';
import { HeaderSubText } from './components/HeaderSubText';
import LiteRFQ from './components/LiteRFQ/LiteRFQ';
import QuickResponderBadge from './components/QuickResponderBadge/QuickResponderBadge';
import SubmitCTA from './components/SubmitCTA';
import ViewConversation from './components/ViewConversation';
import { WhyUseKnot } from './components/WhyUseKnot';
import type { UseInlineRfqFormReturn } from './hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from './hooks/useTrackInlineForm/use-track-inline-form';
import {
	checkIsTablet,
	isPostPrimary,
	loadStoredRfqFormData,
	resolveButtonText,
} from './utils';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	context: UseInlineRfqFormReturn;
	handleSubmittedInlineRfq?: () => void;
}

type InlineRfqProps = StateProps & OwnProps;

const InlineRfq: FC<InlineRfqProps> = (props) => {
	const {
		conversations,
		vendorId = '',
		vendorRaw,
		context,
		handleSubmittedInlineRfq,
		inlineFormFields,
		viewport,
		categoryCode,
		membership,
	} = props;

	const {
		isSubmitting,
		readyToSubmit,
		areErrorsInForm,
		shouldShowErrors,
		handleCtaClick,
		headerText,
		subheaderText,
		isModalOpen,
		closeRfqModal,
		handleSubmit,
		values,
		pageType,
	} = context;

	const trackEditInlineForm = useTrackInlineForm('edit', context.initiator);
	const trackCloseInlineForm = useTrackInlineForm('close', context.initiator);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isMobile && !isTabletOrMedium;
	const isDirectory = pageType !== 'storefront';

	const isInDetailedPricing = useDetailedPricing();

	const uiReadabilityEnhancementsVariant = useAppSelector(
		uiReadabilityEnhancementsAssignmentSelector,
	);
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();

	const shouldRemoveSubtitle =
		isDirectory && !['FLO', 'REC', 'WPH', 'VID'].includes(categoryCode);

	const dispatch = useAppDispatch();

	const shouldShowScannability = useStorefrontFeatureScannability();

	// Sticky CTA shadow handling
	const stickyCtaContainerRef = useRef<HTMLDivElement>(null);
	const { blockSize: stickyShadowOffset } = useElementDimensionsObserver(
		stickyCtaContainerRef,
	);

	useEffect(() => {
		if (conversations[vendorId] && isModalOpen) {
			closeRfqModal();
		} else {
			loadStoredRfqFormData({ context, categoryCode, membership });
		}
	}, [isModalOpen]);

	useEffect(() => {
		if (uiReadabilityEnhancementsVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'uiReadabilityEnhancements assignment: ',
				uiReadabilityEnhancementsVariant,
			);
			dispatch(
				reportServerSideExperiment(experiments.uiReadabilityEnhancements),
			);
		}
	}, [uiReadabilityEnhancementsVariant]);

	useEffect(() => {
		if (!isSubmitting && readyToSubmit) {
			if (membership.member) {
				handleSubmit(true);
				handleSubmittedInlineRfq?.();
			} else {
				handleSubmit().then((submitted) => {
					if (submitted) {
						handleSubmittedInlineRfq?.();
					}
				});
			}
		}
	}, [inlineFormFields]);

	const [shouldHideLiteRFQ, setShouldHideLiteRFQ] = useState(false);

	const areErrorsInLiteRFQ = () =>
		values.firstName.error !== '' ||
		values.lastName.error !== '' ||
		values.emailAddress.error !== '' ||
		values.weddingDate.error !== '' ||
		values.guestCount.error !== '';

	const areLiteRfqFieldsFilled = () =>
		values.firstName.value !== '' &&
		values.lastName.value !== '' &&
		values.emailAddress.value !== '' &&
		values.weddingDate.value !== '' &&
		values.guestCount.value !== '';

	const userExists =
		!!membership.member || // existing logged in user or new logged in user (lead auto-account-creation)
		!!Object.keys(conversations).length; // existing logged out user after sending at least 1 lead

	useEffect(() => {
		// avoid LiteRFQ popping up on first lead
		if (!userExists) {
			setShouldHideLiteRFQ(true);
		}
	}, [userExists]);

	const shouldRenderLiteRFQ = useMemo(() => {
		return (
			// T&Cs need to be accepted
			userExists &&
			areLiteRfqFieldsFilled() &&
			!areErrorsInLiteRFQ() &&
			!shouldHideLiteRFQ // when edit button was clicked on the Lite RFQ
		);
	}, [userExists, values, shouldHideLiteRFQ]);

	const shouldDisplayVendorWidget = useMemo(
		() =>
			vendorRaw?.bio?.name &&
			vendorRaw?.bio?.displayRole &&
			vendorRaw?.bio?.photo?.url,
		[vendorRaw?.bio, pageType, isModalOpen],
	);

	const includeQuickResponderBadge = Boolean(
		vendorRaw?.vendorBehavior?.quickResponder,
	);

	const shouldRenderQuickResponderBadge = Boolean(
		isDesktop &&
			includeQuickResponderBadge &&
			(!shouldDisplayVendorWidget || isDirectory),
	);

	if (
		isDesktop &&
		isPostPrimary(
			conversations,
			vendorId,
			isModalOpen,
			shouldShowErrors,
			areErrorsInForm,
		)
	) {
		return <ViewConversation />;
	}

	const handleCloseModal = () => {
		trackCloseInlineForm();
		closeRfqModal();
	};

	return (
		<>
			<div
				className={classNames({
					[Styles.mobileWrapper]: isMobile && !isTabletOrMedium,
					[Styles.tabletWrapper]: isTabletOrMedium,
					[Styles.inlineSticky]: isDesktop && !isModalOpen,
					[Styles.spacingTop]: isUIReadabilityEnhancementsVariant,
				})}
				data-detailed-pricing-rfq={isInDetailedPricing}
				data-scannability={shouldShowScannability}
			>
				<div
					className={classNames({
						[Styles.inlineWrapper]: isDesktop && !isModalOpen,
					})}
				>
					<div
						className={classNames({
							[Styles.basePadding]: isDesktop && !isModalOpen,
						})}
					>
						{isTabletOrMedium && (
							<div
								className={classNames({
									[Styles.headerCloseButton]: isTabletOrMedium,
								})}
							>
								<IconButton
									aria-label="close"
									// @ts-expect-error: property 'block' does not exist on 'IconButton'
									block
									name="close"
									onClick={handleCloseModal}
									size="sm"
								/>
							</div>
						)}
						<div className={Styles.headerTextWrapper}>
							<Subhead bold>{headerText || 'Message vendor'}</Subhead>
							<Caption className={Styles.requiredText}>*=Required</Caption>
						</div>
						{!shouldRemoveSubtitle && (
							<HeaderSubText
								isMobile={isMobile}
								isTabletOrMedium={isTabletOrMedium}
								pricingText={subheaderText}
							/>
						)}
						{shouldDisplayVendorWidget && (
							<VendorInfo
								bio={vendorRaw?.bio}
								isInLiteRFQ={true}
								includeQuickResponderBadge={
									includeQuickResponderBadge && !isDirectory
								}
								vendorName={vendorRaw?.name}
							/>
						)}
						{!shouldRenderLiteRFQ && <FormBody context={context} />}
						{shouldRenderLiteRFQ && (
							<LiteRFQ
								context={context}
								buttonHandler={() => {
									trackEditInlineForm();
									setShouldHideLiteRFQ(true);
								}}
							/>
						)}
						{!shouldRenderLiteRFQ && (
							<div
								className={classNames(Styles.avoidStickyCover, {
									[Styles.extraSpacingDesktop]: // because RfqLegalCopy is not present
										membership.member && !isModalOpen,
								})}
							>
								<WhyUseKnot />
							</div>
						)}
					</div>
					{isDesktop && isModalOpen && (
						<>
							<div className={Styles.stickyShadowCover} />
							<div
								style={{
									bottom: `${stickyShadowOffset}px`,
								}}
								className={Styles.stickyShadow}
							/>
						</>
					)}
					<div
						className={classNames({
							[Styles.stickyCta]: isMobile,
							[Styles.desktopCta]: isDesktop && !isModalOpen,
							[Styles.stickyDesktopCta]: isDesktop && isModalOpen,
						})}
						ref={stickyCtaContainerRef}
					>
						{!membership.member && (
							<div
								className={
									isModalOpen
										? Styles.legalCopyContainerModal
										: Styles.legalCopyContainer
								}
							>
								<RfqLegalCopy
									btnText={resolveButtonText({
										catCode: categoryCode,
										isMobile,
										isTablet: isTabletOrMedium,
									})}
								/>
							</div>
						)}
						<SubmitCTA
							isSubmitting={!membership.member && isSubmitting}
							handleSubmit={handleCtaClick}
						/>
						{shouldRenderQuickResponderBadge && (
							<div className={Styles.quickResponderBadgeWrapper}>
								<QuickResponderBadge
									small={shouldShowScannability}
									isDirectory={isDirectory}
								/>
							</div>
						)}
						{membership.member && (
							<div className={Styles.termsContainer}>
								<a
									href="https://www.theknotww.com/terms-of-use"
									rel="noopener noreferrer"
									target="_blank"
									className={Styles.termsLink}
								>
									Terms of Use
								</a>
								<a
									href="https://www.theknotww.com/privacy-policy"
									rel="noopener noreferrer"
									target="_blank"
									className={Styles.termsLink}
								>
									Privacy Policy
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	inlineFormFields: state.rfq.inline.fields,
	categoryCode: state.category.code,
	membership: state.membership,
	conversations: state.messagedVendors.conversations,
	vendorRaw: state.vendor.vendorRaw,
	vendorId: state.vendor.vendorRaw?.id,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(InlineRfq);
